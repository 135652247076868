

//6-aug-24 5:08pm

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import EmailWithAI from './EmailWithAI';

import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <nav className="navbar">
          
          <Link to="/" className="nav-link">Email with AI</Link>
         
        </nav>
        <Routes>
         
          <Route path="/" element={<EmailWithAI />} />
         
        </Routes>
      </div>
    </Router>
  );
}

export default App;
