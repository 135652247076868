
//3-aug-27 11:16pm

import React, { useState } from 'react';
import axios from 'axios';
import './EmailWithAI.css';

const EmailWithAI = () => {
  const [prompt, setPrompt] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [error, setError] = useState('');

  const handleGenerateContent = async () => {
    try {
      console.log('starting AI part');
      const response = await axios.post('https://aiemail.marriedtothemopnewsletter.online/generate-content', { prompt });
      //const response = await axios.post('http://localhost:3002/generate-content', { prompt });
      setSubject(response.data.subject);
      setBody(response.data.body);
      setError('');
    } catch (error) {
      console.error('Error generating content:', error);
      setError('Failed to generate content. Please try again.');
    }
  };

  return (
    <div className="email-ai-container">
      <h2>Email Content Generator</h2>
      <textarea
        placeholder="What do you want to write about?"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <button onClick={handleGenerateContent}>Write for me!</button>
      {error && <p className="error">{error}</p>}
      {subject && (
        <div>
          <h3>Generated Subject:</h3>
          <p>{subject}</p>
        </div>
      )}
      {body && (
        <div>
          <h3>Generated Body:</h3>
          <p>{body}</p>
        </div>
      )}
    </div>
  );
};

export default EmailWithAI;

